import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateUserModal from '../components/CreateUserModal';
import { useAuthContext } from '../contexts/AuthContext';
import icons from '../assets/constants/icons';

interface User {
  id: string;
  email: string;
  full_name: string;
  is_superuser: boolean;
  created_at: string;
  last_login: string | null;
  count: number;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const navigate = useNavigate();
  const API_URL = import.meta.env.VITE_API_URL as string;
  const [pageSize, setPageSize] = useState<number>(10);

  const { getToken, refreshAccessToken } = useAuthContext();

  useEffect(() => {
    void fetchUsers();
    void fetchUserCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const fetchUsers = async () => {
    setIsLoading(true);
    setError(null);

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/admin/users?page=${currentPage}&size=${pageSize}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 403) {
        const newToken = await refreshAccessToken();
        if (newToken) {
          return fetchUsers();
        } else {
          navigate('/login');
          return;
        }
      } else if (response.status === 401) {
        navigate('/login');
      }

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = (await response.json()) as User[];
      setUsers(data);
    } catch (err) {
      setError('An error occurred while fetching users');
      console.error('Error fetching users:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserCount = async () => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/admin/users/count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user count');
      }

      const data = (await response.json()) as User;
      setTotalUsers(data.count);
    } catch (err) {
      console.error('Error fetching user count:', err);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(totalUsers / pageSize);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleCreateUser = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const createUser = async (userData: {
    email: string;
    password: string;
    full_name: string;
    is_superuser: boolean;
  }) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/admin/users`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Failed to create user');
      }

      const newUser = (await response.json()) as User;
      setUsers((prevUsers) => [...prevUsers, newUser]);
      setTotalUsers((prev) => prev + 1);
      alert('User created successfully');
    } catch (err) {
      setError('An error occurred while creating the user');
      console.error('Error creating user:', err);
    }
  };

  const handleSelectUser = (email: string) => {
    setSelectedUsers((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email],
    );
  };

  const handleDeleteUsers = async () => {
    if (selectedUsers.length === 0) return;

    const confirmDelete = window.confirm(
      `¿Segur@ que quieres eliminar ${selectedUsers.length} usuario(s)?`,
    );
    if (!confirmDelete) return;

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    setError(null);

    const deletedEmails: string[] = [];
    const failedEmails: string[] = [];

    for (const email of selectedUsers) {
      try {
        const response = await fetch(`${API_URL}/admin/users/${encodeURIComponent(email)}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          deletedEmails.push(email);
        } else if (response.status === 404) {
          failedEmails.push(email);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (err) {
        console.error(`Error deleting user ${email}:`, err);
        failedEmails.push(email);
      }
    }

    setUsers((prevUsers) => prevUsers.filter((user) => !deletedEmails.includes(user.email)));
    setSelectedUsers([]);
    setTotalUsers((prev) => prev - deletedEmails.length);

    setIsLoading(false);

    if (failedEmails.length > 0) {
      setError(`Failed to delete the following users: ${failedEmails.join(', ')}`);
    }

    if (deletedEmails.length > 0) {
      alert(`Successfully deleted ${deletedEmails.length} user(s)`);
    }

    void fetchUsers();
    void fetchUserCount();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      setPageSize(totalUsers); // Set pageSize to totalDevices when searching
      setCurrentPage(1);
    } else {
      setPageSize(10); // Reset to default pageSize or the previous value
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page
  };

  // Add this function to handle user updates
  const handleUpdateUser = async (
    email: string,
    updatedData: {
      email?: string;
      full_name?: string;
      is_superuser?: boolean;
    },
  ) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/admin/users/${encodeURIComponent(email)}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error('Failed to update user');
      }

      const updatedUser = (await response.json()) as User;
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.email === updatedUser.email ? updatedUser : user)),
      );
      alert('User updated successfully');
    } catch (err) {
      setError('An error occurred while updating the user');
      console.error('Error updating user:', err);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Usuarios</h2>
        <div>
          <input
            type="text"
            placeholder="Buscar por Email or Nombre."
            value={searchTerm}
            onChange={(e) => void handleSearchChange(e)}
            className="border rounded py-2 px-4 mr-2"
          />
          <button
            onClick={() => void handleCreateUser()}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Crear Usuario
          </button>
          {selectedUsers.length > 0 && (
            <button
              onClick={() => void handleDeleteUsers()}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            >
              Borrar Seleccionado ({selectedUsers.length})
            </button>
          )}
        </div>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!isLoading && !error && filteredUsers && filteredUsers.length > 0 && (
        <>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selectedUsers.length === filteredUsers.length}
                    onChange={() => {
                      if (selectedUsers.length === filteredUsers.length) {
                        setSelectedUsers([]);
                      } else {
                        setSelectedUsers(filteredUsers.map((user) => user.email));
                      }
                    }}
                  />
                </th>
                <th className="py-2 px-4 border-b">Nombre</th>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Creado el:</th>
                <th className="py-2 px-4 border-b">Último inicio de sesión</th>
                <th className="py-2 px-4 border-b">Super Usuario</th>
                <th className="py-2 px-4 border-b">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.email)}
                      onChange={() => handleSelectUser(user.email)}
                    />
                  </td>
                  <td className="py-2 px-4 border-b">{user.full_name}</td>
                  <td className="py-2 px-4 border-b">{user.email}</td>
                  <td className="py-2 px-4 border-b">{formatDate(user.created_at)}</td>
                  <td className="py-2 px-4 border-b">{formatDate(user.last_login)}</td>
                  <td className="py-2 px-4 border-b">{user.is_superuser ? 'Sí' : 'No'}</td>
                  <td className="py-2 px-4 border-b flex-row space-y-1 space-x-1">
                    {/* Button to update email */}
                    <button
                      onClick={() => {
                        const newEmail = prompt(
                          'Ingresa nuevo Email (Dejar en blanco para NO cambiarlo):',
                        );
                        if (newEmail !== null) {
                          // Check if the user didn't cancel the prompt
                          const updatedData = {
                            email: newEmail.trim() || user.email, // Use trimmed value or keep current
                            full_name: user.full_name,
                            is_superuser: user.is_superuser,
                          };
                          void handleUpdateUser(user.email, updatedData);
                        }
                      }}
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                    >
                      <img src={icons.email} className="w-[20px] h-[20px]" />
                    </button>

                    {/* Button to update full name */}
                    <button
                      onClick={() => {
                        const newFullName = prompt(
                          'Ingresa el Nombre completo (Dejar en blanco para NO cambiarlo):',
                        );
                        if (newFullName !== null) {
                          // Check if the user didn't cancel the prompt
                          const updatedData = {
                            email: user.email,
                            full_name: newFullName.trim() || user.full_name, // Use trimmed value or keep current
                            is_superuser: user.is_superuser,
                          };
                          void handleUpdateUser(user.email, updatedData);
                        }
                      }}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded"
                    >
                      <img src={icons.name} className="w-[20px] h-[20px]" />
                    </button>

                    {/* Button to update superuser status */}
                    <button
                      onClick={() => {
                        const isSuperuser = confirm(
                          'Cambiar el super usuario? (OK para Sí, Cancel para No)',
                        );
                        const updatedData = {
                          email: user.email,
                          full_name: user.full_name,
                          is_superuser: isSuperuser, // Directly use the result of confirm
                        };
                        void handleUpdateUser(user.email, updatedData);
                      }}
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded"
                    >
                      <img src={icons.admin} className="w-[20px] h-[20px]" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
            >
              Anterior
            </button>
            <span>
              Página {currentPage} de {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
            >
              Siguiente
            </button>
          </div>
          <div className="mt-4 flex justify-end">
            <label htmlFor="pageSize" className="mr-2">
              Elementos por página:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="border rounded py-0 px-2"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
        </>
      )}
      {!isLoading && !error && (!filteredUsers || filteredUsers.length === 0) && (
        <p>No users found.</p>
      )}
      <CreateUserModal
        isOpen={isCreateModalOpen}
        onClose={handleCloseCreateModal}
        onCreateUser={createUser}
      />
    </div>
  );
};

export default Users;
