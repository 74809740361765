import { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import icons from '../assets/constants/icons';

function AdminDashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUserOpen, setIsUserOpen] = useState(true);

  const { logout } = useAuthContext();
  const [currentUser, setCurrentUser] = useState<{
    full_name: string;
    email: string;
  } | null>(null);
  const API_URL = import.meta.env.VITE_API_URL as string;

  interface User {
    email: string;
    full_name: string;
    phone_number: string;
    date_of_birth: string;
  }

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const token = localStorage.getItem('access_token'); // Adjust this if you use a different method to get the token
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = (await response.json()) as User;
          setCurrentUser(data);
        } else {
          console.error('Failed to fetch current user');
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    void fetchCurrentUser();
  }, [API_URL]);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`bg-gray-800 text-white w-64 ${isSidebarOpen ? '' : 'hidden'} md:flex flex-col h-screen`}
      >
        <div className="p-4">
          <h1 className="flex items-center text-2xl font-semibold">
            <img src={icons.connectia_icon} alt="Connectia" className="w-[40px] h-[40px] mr-3" />
            Panel de Administración
          </h1>
        </div>

        <nav className="mt-4 flex-grow">
          <Link
            to="/admin/users"
            className={
              isUserOpen
                ? 'flex items-center block py-2 px-4 hover:bg-gray-700 bg-black'
                : 'flex items-center block py-2 px-4 hover:bg-gray-700'
            }
            onClick={() => void setIsUserOpen(true)}
          >
            <img src={icons.profile} alt="Profile" className="w-[20px] h-[20px] mr-3" />
            Usuarios
          </Link>
          <Link
            to="/admin/devices"
            className={
              isUserOpen
                ? 'flex items-center block py-2 px-4 hover:bg-gray-700'
                : 'flex items-center block py-2 px-4 hover:bg-gray-700 bg-black'
            }
            onClick={() => void setIsUserOpen(false)}
          >
            <img src={icons.home} alt="Home" className="w-[20px] h-[20px] mr-3" />
            Dispositivos
          </Link>
        </nav>

        {/* User Info */}
        {currentUser && (
          <div className="p-4 border-t border-gray-700 bg-gray-900 flex items-center justify-between flex-row mt-auto">
            <div>
              <h2 className="text-lg font-semibold text-blue-500">Usuario</h2>
              <p className="text-sm text-white">{currentUser.full_name}</p>
              <p className="text-sm text-gray-400">{currentUser.email}</p>
            </div>
            <button
              onClick={() => {
                const confirmFirst = window.confirm('¿Estás segur@ que quieres cerrar sesión?');
                if (confirmFirst) {
                  logout();
                }
              }}
              className="ml-4"
            >
              <img src={icons.logout} alt="Logout" className="w-[30px] h-[30px]" />
            </button>
          </div>
        )}
      </aside>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="md:hidden text-gray-500 hover:text-gray-600"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              {isUserOpen ? (
                <h2 className="text-xl font-semibold text-gray-800">Lista de Usuarios</h2>
              ) : (
                <h2 className="text-xl font-semibold text-gray-800">Lista de dispositivos</h2>
              )}
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;
