import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import images from '../assets/constants/images';
import icons from '../assets/constants/icons';

interface Keys {
  access_token: string;
  refresh_token: string;
}

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const API_URL = import.meta.env.VITE_API_URL as string;
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [format, setFormat] = useState('font-bold text-blue-500');
  const [message, setMessage] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    const formData = new URLSearchParams();
    formData.append('username', email);
    formData.append('password', password);

    try {
      const response = await fetch(`${API_URL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });

      if (response.status === 200) {
        const data = (await response.json()) as Keys;
        setSuccessMessage('Login successful');
        login(data.access_token, data.refresh_token);
        navigate('/admin');
      } else {
        setError('Invalid credentials. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  const hanldeForgot = async (e: React.FormEvent, email: string) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/users/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (response.status === 200) {
        setForgot(false);
        setMessage(true);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <aside className="flex items-center justify-center w-full max-w-lg p-6 bg-white rounded-xl shadow-md">
        <div className="flex flex-col space-y-8">
          <div className="flex justify-center">
            <img src={icons.connectia_icon} className="w-[115px] h-[100px]" />
          </div>
          <h2 className="text-4xl font-extrabold text-center text-gray-900">Iniciar Sesión</h2>
          {forgot ? (
            <form className="space-y-6" onSubmit={(e) => void hanldeForgot(e, email)}>
              <div className="flex flex-col space-y-4">
                <div className="font-semibold text-gray-900 text-lg">Correo Electrónico</div>
                <label htmlFor="email-address" className="sr-only">
                  Correo electrónico
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <h3
                  className={format}
                  onMouseEnter={() => setFormat('font-bold text-blue-700')}
                  onMouseLeave={() => setFormat('font-bold text-blue-500')}
                  onClick={() => {
                    setForgot(false);
                    setFormat('font-bold text-blue-500');
                  }}
                >
                  ¿Necesitas iniciar sesión?
                </h3>
              </div>

              {error && <div className="text-red-500 text-sm text-center">{error}</div>}

              {successMessage && (
                <div className="text-green-500 text-sm text-center">{successMessage}</div>
              )}

              <button
                type="submit"
                className="w-full max-w-md py-3 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                disabled={isLoading}
              >
                {isLoading ? 'Iniciando...' : 'Recuperar contraseña'}
              </button>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={(e) => void handleSubmit(e)}>
              <div className="flex flex-col space-y-4">
                <div className="font-semibold text-gray-900 text-lg">Correo Electrónico</div>
                <label htmlFor="email-address" className="sr-only">
                  Correo electrónico
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className="font-semibold text-gray-900 text-lg">Contraseña</div>
                <label htmlFor="password" className="sr-only">
                  Contraseña
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => void togglePasswordVisibility()}
                    className="absolute right-4 top-2"
                  >
                    <img
                      src={showPassword ? icons.eyeHide : icons.eye}
                      alt="Toggle password visibility"
                      className="w-6 h-6"
                    />
                  </button>
                </div>
                <h3
                  className={format}
                  onMouseEnter={() => setFormat('font-bold text-blue-700')}
                  onMouseLeave={() => setFormat('font-bold text-blue-500')}
                  onClick={() => {
                    setForgot(true);
                    setFormat('font-bold text-blue-500');
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </h3>
              </div>

              {error && <div className="text-red-500 text-sm text-center">{error}</div>}

              {successMessage && (
                <div className="text-green-500 text-sm text-center">{successMessage}</div>
              )}

              <button
                type="submit"
                className="w-full max-w-md py-3 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                disabled={isLoading}
              >
                {isLoading ? 'Iniciando...' : 'Empezar'}
              </button>

              {message ? (
                <div>
                  <h1 className="text-sm font-medium text-gray-400">
                    Se ha enviado un correo electrónico de recuperación,
                  </h1>
                  <h1 className="text-sm font-medium text-gray-400">
                    revisa carpeta de spam en caso de ser necesario.
                  </h1>
                </div>
              ) : (
                <h1></h1>
              )}
            </form>
          )}
        </div>
      </aside>

      <div className="flex items-center justify-center h-full py-10 px-10 ml-20">
        <img src={images.login} alt="Login" />
      </div>
    </div>
  );
};

export default LoginPage;
