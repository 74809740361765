import React, { useState } from 'react';

interface CreateDeviceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateDevices: (count: number) => Promise<void>;
}

const CreateDeviceModal: React.FC<CreateDeviceModalProps> = ({
  isOpen,
  onClose,
  onCreateDevices,
}) => {
  const [deviceCount, setDeviceCount] = useState<number>(1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceCount(Number(e.target.value));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onCreateDevices(deviceCount);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Crear dispositivos</h2>
        <form onSubmit={(e) => void handleSubmit(e)}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deviceCount">
              Numero de dispositivos
            </label>
            <input
              type="number"
              id="deviceCount"
              value={deviceCount}
              onChange={handleChange}
              min="1"
              max="100"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Crear dispositivos
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDeviceModal;
