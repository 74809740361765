import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import CreateDeviceModal from '../components/CreateDeviceModal';

interface Device {
  owner_email: string;
  id: string;
  display_id: string;
  token: string;
  owner_id: string | null;
  is_provisioned: boolean;
  last_activity_time: string | null;
  last_update_time: string | null;
  created_at: string;
  count: number;
}

const Devices: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDevices, setTotalDevices] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const [isCreateDeviceModalOpen, setIsCreateDeviceModalOpen] = useState(false);
  const navigate = useNavigate();
  const API_URL = import.meta.env.VITE_API_URL as string;
  const [pageSize, setPageSize] = useState<number>(10);
  const [showUnprovisioned, setShowUnprovisioned] = useState(false); // New state for filter

  const { getToken, refreshAccessToken } = useAuthContext();

  useEffect(() => {
    void fetchDevices();
    void fetchDeviceCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, showUnprovisioned]); // Add showUnprovisioned to dependencies

  const fetchDevices = async () => {
    setIsLoading(true);
    setError(null);
    const token = getToken();

    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const url = showUnprovisioned
        ? `${API_URL}/admin/devices/status?page=${currentPage}&size=${pageSize}`
        : `${API_URL}/admin/devices?page=${currentPage}&size=${pageSize}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 403) {
        const newToken = await refreshAccessToken();
        if (newToken) {
          // Retry the request with the new token
          return fetchDevices(); // Call the function again
        } else {
          navigate('/login');
          return;
        }
      } else if (response.status === 401) {
        navigate('/login');
      }

      if (!response.ok) {
        throw new Error('Failed to fetch devices');
      }

      const data = (await response.json()) as Device[];
      setDevices(data);
    } catch (err) {
      setError('An error occurred while fetching devices');
      console.error('Error fetching devices:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDeviceCount = async () => {
    const token = getToken();

    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const count = showUnprovisioned
        ? `${API_URL}/admin/devices/count?provision=true`
        : `${API_URL}/admin/devices/count`;

      const response = await fetch(count, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch device count');
      }

      const data = (await response.json()) as Device;
      setTotalDevices(data.count);
    } catch (err) {
      console.error('Error fetching device count:', err);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setSelectedDevices([]);
  };

  const handleSelectDevice = (displayId: string) => {
    setSelectedDevices((prev) =>
      prev.includes(displayId) ? prev.filter((id) => id !== displayId) : [...prev, displayId],
    );
  };

  const handleDeleteDevices = async () => {
    if (selectedDevices.length === 0) return;

    const confirmDelete = window.confirm(
      `¿Segur@ que quieres eliminar ${selectedDevices.length} dispositivo(s)?`,
    );
    if (!confirmDelete) return;

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    setError(null);

    const deletedDisplayIds: string[] = [];
    const failedDisplayIds: string[] = [];

    for (const displayId of selectedDevices) {
      try {
        const response = await fetch(`${API_URL}/admin/devices/${encodeURIComponent(displayId)}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          deletedDisplayIds.push(displayId);
        } else if (response.status === 404) {
          failedDisplayIds.push(displayId);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (err) {
        console.error(`Error deleting device ${displayId}:`, err);
        failedDisplayIds.push(displayId);
      }
    }

    setDevices((prevDevices) =>
      prevDevices.filter((device) => !deletedDisplayIds.includes(device.display_id)),
    );
    setSelectedDevices([]);
    setTotalDevices((prev) => prev - deletedDisplayIds.length);

    setIsLoading(false);

    if (failedDisplayIds.length > 0) {
      setError(`Failed to delete the following devices: ${failedDisplayIds.join(', ')}`);
    }

    if (deletedDisplayIds.length > 0) {
      alert(`Successfully deleted ${deletedDisplayIds.length} device(s)`);
    }

    // Refresh the device list and count
    void fetchDevices();
    void fetchDeviceCount();
  };

  const handleCreateQR = async () => {
    if (selectedDevices.length === 0) return;

    const confirmQr = window.confirm(
      `¿Segur@ que quieres crear ${selectedDevices.length} QR(s) de dispositivo(s)?`,
    );
    if (!confirmQr) return;

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    setError(null);

    const failedQRIds: string[] = [];

    for (const displayId of selectedDevices) {
      try {
        const response = await fetch(
          `${API_URL}/admin/devices/${encodeURIComponent(displayId)}/qr`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.ok) {
          const blob = await response.blob(); // Get the response as a Blob
          const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

          // Create a link element
          const a = document.createElement('a');
          a.href = url;
          a.download = `${displayId}.png`; // Set the file name for download
          document.body.appendChild(a); // Append the link to the body
          a.click(); // Programmatically click the link to trigger the download
          a.remove(); // Remove the link from the document
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } else if (response.status === 404) {
          failedQRIds.push(displayId);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (err) {
        console.error(`Error creating QR for device ${displayId}:`, err);
        failedQRIds.push(displayId);
      }
    }

    setIsLoading(false);

    if (failedQRIds.length > 0) {
      setError(`Failed to create QR for the following devices: ${failedQRIds.join(', ')}`);
    }
  };

  const handleCreateDevice = () => {
    setIsCreateDeviceModalOpen(true);
  };

  const createDevices = async (count: number) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/admin/devices?count=${count}`, {
        // Pass count as a query parameter
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to create devices');
      }

      const newDevices = (await response.json()) as Device[];
      setDevices((prevDevices) => [...newDevices, ...prevDevices]);
      setTotalDevices((prev) => prev + newDevices.length);
      alert(`Successfully created ${newDevices.length} device(s)`);

      // Refresh the device list and count
      void fetchDevices();
      void fetchDeviceCount();
    } catch (err) {
      setError('An error occurred while creating devices');
      console.error('Error creating devices:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleRegistration = async (displayId: string, currentStatus: boolean) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/admin/devices/${displayId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify({ is_provisioned: !currentStatus }), // Send the updated value in the body
      });

      if (!response.ok) {
        throw new Error('Failed to update device registration status');
      }

      setDevices((prevDevices) =>
        prevDevices.map((device) =>
          device.display_id === displayId ? { ...device, is_provisioned: !currentStatus } : device,
        ),
      );
    } catch (err) {
      setError('An error occurred while updating the device registration status');
      console.error('Error updating device registration status:', err);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      setPageSize(totalDevices); // Set pageSize to totalDevices when searching
      setCurrentPage(1);
    } else {
      setPageSize(10); // Reset to default pageSize or the previous value
    }
  };

  const handleFilterToggle = () => {
    setShowUnprovisioned((prev) => !prev);
    setCurrentPage(1); // Reset to the first page when toggling filter
  };

  const filteredDevices = devices.filter((device) =>
    device.display_id.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString(); // This will format the date and time according to the user's locale
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page
  };

  const handleSelectAllDevices = () => {
    if (selectedDevices.length === filteredDevices.length) {
      setSelectedDevices([]);
    } else {
      const allDisplayIds = filteredDevices.map((device) => device.display_id);
      setSelectedDevices(allDisplayIds);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Dispositivos</h2>
        <div>
          {selectedDevices.length > 0 ? (
            <div>
              <button
                onClick={() => void handleDeleteDevices()}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Borrar seleccionado ({selectedDevices.length})
              </button>
              <button
                onClick={() => void handleCreateQR()}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Crear QR ({selectedDevices.length})
              </button>
            </div>
          ) : (
            <div>
              <input
                type="text"
                placeholder="Buscar por ID"
                value={searchTerm}
                onChange={handleSearchChange}
                className="border rounded py-2 px-4 mr-2"
              />
              <button
                onClick={handleFilterToggle}
                className={`${showUnprovisioned ? 'bg-blue-500 hover:bg-blue-600' : 'bg-red-500 hover:bg-red-600'} text-white font-bold py-2 px-4 rounded mr-2`}
              >
                {showUnprovisioned ? 'Mostrar todo' : 'Mostrar deshabilitados'}
              </button>
              <button
                onClick={handleCreateDevice}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Crear Dispositivo
              </button>
            </div>
          )}
        </div>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!isLoading && !error && filteredDevices && filteredDevices.length > 0 && (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b sticky left-0 bg-white">
                    <input
                      type="checkbox"
                      checked={selectedDevices.length === filteredDevices.length}
                      onChange={handleSelectAllDevices}
                    />
                  </th>
                  <th className="py-2 px-4 border-b">ID dispositivo</th>
                  <th className="py-2 px-4 border-b">Token</th>
                  <th className="py-2 px-4 border-b">Acciones</th>
                  <th className="py-2 px-4 border-b">Última lectura</th>
                  <th className="py-2 px-4 border-b">Dueño del dispositivo</th>
                  <th className="py-2 px-4 border-b">Creado el:</th>
                </tr>
              </thead>
              <tbody>
                {filteredDevices.map((device) => (
                  <tr key={device.id}>
                    <td className="py-2 px-4 border-b sticky left-0 bg-white">
                      <input
                        type="checkbox"
                        checked={selectedDevices.includes(device.display_id)}
                        onChange={() => handleSelectDevice(device.display_id)}
                      />
                    </td>
                    <td className="py-2 px-4 border-b">{device.display_id}</td>
                    <td className="py-2 px-4 border-b">{device.token}</td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() =>
                          void handleToggleRegistration(device.display_id, device.is_provisioned)
                        }
                        className={`px-2 py-1 rounded ${
                          device.is_provisioned
                            ? 'bg-red-500 hover:bg-red-600'
                            : 'bg-green-500 hover:bg-green-600'
                        } text-white`}
                      >
                        {device.is_provisioned ? 'Deshabilitar' : 'Habilitar'}
                      </button>
                    </td>
                    <td className="py-2 px-4 border-b">{formatDate(device.last_activity_time)}</td>
                    <td className="py-2 px-4 border-b">{device.owner_email || 'N/A'}</td>
                    <td className="py-2 px-4 border-b">{formatDate(device.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
            >
              Anterior
            </button>
            <span>
              Página {currentPage} de {Math.ceil(totalDevices / pageSize)}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalDevices / pageSize)}
              className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
            >
              Siguiente
            </button>
          </div>
          <div className="mt-4 flex justify-end">
            <label htmlFor="pageSize" className="mr-2">
              Elementos por página:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="border rounded py-0 px-2"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
        </>
      )}
      {!isLoading && !error && (!filteredDevices || filteredDevices.length === 0) && (
        <p>No devices found.</p>
      )}
      <CreateDeviceModal
        isOpen={isCreateDeviceModalOpen}
        onClose={() => setIsCreateDeviceModalOpen(false)}
        onCreateDevices={createDevices}
      />
    </div>
  );
};

export default Devices;
