import logout from '../icons/logout-Red.png';
import connectia_icon from '../icons/Connectia_Icon.png';
import admin from '../icons/admin-icon.png';
import name from '../icons/name-icon.png';
import email from '../icons/email-icon.png';
import eye from '../icons/eye.png';
import eyeHide from '../icons/eye-hide.png';
import profile from '../icons/profile.png';
import home from '../icons/home.png';
import gas from '../icons/gas.png';

export default { logout, connectia_icon, admin, name, email, eye, eyeHide, profile, home, gas };
