import { useState, useEffect } from 'react';

interface Keys {
  access_token: string;
  refresh_token: string;
}

export const useAuth = () => {
  const API_URL = import.meta.env.VITE_API_URL as string;

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const access_token = localStorage.getItem('access_token');
    return access_token != null;
  });

  useEffect(() => {
    checkAuth(); // Check authentication status on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (acces_token: string, refresh_token: string) => {
    const expirationTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    document.cookie = `access_token=${acces_token}; path=/; expires=${expirationTime.toUTCString()}; secure; samesite=strict`;
    localStorage.setItem('access_token', acces_token);
    document.cookie = `refresh_token=${refresh_token}; path=/; expires=${expirationTime.toUTCString()}; secure; samesite=strict`;
    localStorage.setItem('refresh_token', refresh_token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    document.cookie = 'access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'refresh_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'token_type=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
  };

  const checkAuth = () => {
    const token = getToken();
    setIsAuthenticated(!!token);
  };

  const getToken = () => {
    const tokenFromCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('access_token='));
    if (tokenFromCookie) {
      return tokenFromCookie.split('=')[1];
    }
    return localStorage.getItem('access_token');
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token'); // Assuming you store a refresh token
    if (!refreshToken) return null;

    try {
      const response = await fetch(`${API_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = (await response.json()) as Keys;
      login(data.access_token, data.refresh_token); // Update the access token
      return data.access_token;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logout(); // Log out if refresh fails
      return null;
    }
  };

  return {
    isAuthenticated,
    login,
    logout,
    checkAuth,
    getToken,
    refreshAccessToken,
  };
};
